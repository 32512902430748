import React from 'react'

import { Location } from '@reach/router'

import root from 'utils/windowOrGlobal'
import isMobileDevice from 'utils/isMobileDevice'
import queryString from 'utils/parse-querystring'

import { FullPageLoader } from 'components/Loader'

import { redirectBetaPath } from './beta'

function decodeAndRedirectBeta (url) {
  return redirectBetaPath(decodeURIComponent(url))
}

function appRedirect ({ appUrl, pageUrl, fallBackAppUrl }) {
  appUrl = decodeURIComponent(appUrl)
  pageUrl = decodeAndRedirectBeta(pageUrl)
  fallBackAppUrl = decodeAndRedirectBeta(fallBackAppUrl)

  if (!appUrl || !pageUrl) {
    root.location = '/'
    return
  }

  if (!isMobileDevice()) {
    root.location = pageUrl
    return
  }

  const start = Date.now()

  function redirectFallback (callback) {
    if (fallBackAppUrl) {
      setTimeout(function () {
        if (Date.now() - start < 400) {
          root.location = fallBackAppUrl
          callback()
        }
      }, 300)
    } else {
      callback()
    }
  }

  function redirectWeb () {
    setTimeout(function () {
      if (Date.now() - start < 800) {
        root.location = pageUrl
      }
    }, 300)
  }

  root.location = appUrl
  redirectFallback(() => {
    redirectWeb()
  })
}

export default () => {
  return (
    <Location>
      {({ location }) => {
        const query = queryString(location.search)
        appRedirect(query)

        return <FullPageLoader />
      }}
    </Location>
  )
}
